import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ApiResponseService} from 'src/app/services/api-response.service';
import {GlobalService} from 'src/app/services/global.service';
import {KYCService} from '../../kyc.service';
import {Subscription} from 'rxjs';
import {propertyTypes} from '../../kyc.constant';

@Component({
    selector: 'app-company-metadata-step',
    templateUrl: './company-metadata-step.component.html',
    styleUrls: ['./company-metadata-step.component.scss'],
})
export class CompanyMetadataStepComponent implements OnInit {
    companyMetadataForm: any;
    @Input() disableForm = false;
    constructor(
        public fb: FormBuilder,
        public translate: TranslateService,
        public apiService: ApiResponseService,
        public global: GlobalService,
        public kycDataService: KYCService
    ) {
        this.kycCompanySubscription = this.kycDataService.curentKycCompanyId$.subscribe((data) => {
            this.companyTypeId = data;
        });
    }
    apartmentSelected: any[] = [];
    isKycCompleted = false;
    coopTypes: any[] = [];
    kycCompanySubscription: Subscription;
    companyTypeId: string;
    propertyTypesList: any[] = propertyTypes;
    accountingSoftwares: any[] = [
        {
            accountingSoftwareTypeId: '0',
            value: 'None',
        },
    ];
    ngOnInit() {
        this.companyMetadataForm = this.fb.group({
            companyMetaData: this.fb.group({
                accountingSoftwareTypeId: [null, Validators.required],
                accountingSoftware: ['', [Validators.required, this.noWhitespaceValidator]],
                coopAssociationTypeId: [null, Validators.required],
                otherCoopAssociationType: ['', Validators.required],
                monthlyTransactionAmount: ['', [Validators.required, this.validateMonthlyTransactionAmount]],
            }),
            companyRealEstate: this.fb.group({
                realEstates: [[''], Validators.required],
                otherRealEstateType: ['', [Validators.required, this.noWhitespaceValidator]],
            }),
        });
        this.apiService.getAccountingSoftwareTypes().subscribe((response) => {
            this.accountingSoftwares.push(...response);
        });
        this.apiService.getCoopAssociationTypes().subscribe((response) => {
            this.coopTypes = response;
        });

        this.apiService.getCompanyMetadata().subscribe((response) => {
            response.companyMetaData.accountingSoftwareTypeId = response.companyMetaData.accountingSoftwareTypeId
                ? response.companyMetaData.accountingSoftwareTypeId
                : '0';
            this.companyMetadataForm.get('companyMetaData').patchValue(response.companyMetaData);
            if (!response.companyMetaData.accountingSoftwareType)
                this.companyMetadataForm.get('companyMetaData').get('accountingSoftwareTypeId').setValue(null);
            response.estateTypes.forEach((x) => {
                this.apartmentSelected.push(x.realEstateType);
                if (x.otherType) {
                    this.companyMetadataForm.get('companyRealEstate').get('otherRealEstateType').setValue(x.otherType);
                }
            });
            this.companyMetadataForm.get('companyRealEstate').get('realEstates').setValue(this.apartmentSelected);
        });
        this.disableFormBasedOnCondition();
    }

    disableFormBasedOnCondition() {
        if (this.disableForm) {
            this.companyMetadataForm.disable();
        }
    }
    /**
     * @summary handle
     * @param event
     */
    checkboxOnclick(propertyType): void {
        if (propertyType && !this.apartmentSelected.includes(propertyType)) {
            this.apartmentSelected.push(propertyType);
        } else {
            const index = this.apartmentSelected.indexOf(propertyType);
            if (index !== -1) {
                this.apartmentSelected = this.apartmentSelected.filter((x) => x !== propertyType);
            }
        }
        this.companyMetadataForm.get('companyRealEstate').get('realEstates').setValue(this.apartmentSelected);
    }
    /**
     * @summary checks if the checkbox is disabled
     * @returns boolean
     */
    disableCheckbox(): boolean {
        return false;
    }
    /**
     * Displays the monthly transaction information.
     */
    monthlyTransactionInfo() {
        return this.translate.instant('label.monthly_transaction_amount_info');
        // this.global.basicAlert('', 'label.monthly_transaction_amount_info', true);
    }

    isRealEstateDataValid(): boolean {
        const realEstatesValid = this.companyMetadataForm.get('companyRealEstate.realEstates').valid;
        const otherRealEstateTypeValid = this.companyMetadataForm.get('companyRealEstate.otherRealEstateType').valid;

        if (!realEstatesValid) {
            return false;
        }

        if (this.checkIfOtherApartment() && !otherRealEstateTypeValid) {
            return false;
        }

        return true;
    }
    /**
     * Custom validator function that checks if a control's value contains only whitespace characters.
     * @param control - The form control to validate.
     * @returns An object with a `whitespace` property set to `true` if the value contains only whitespace, or `null` otherwise.
     */
    noWhitespaceValidator(control: AbstractControl): {[key: string]: any} | null {
        const isWhitespace = (control.value || '').trim().length === 0;
        return isWhitespace ? {whitespace: true} : null;
    }
    /**
     * @summary checks if the company meta data is valid
     * @returns
     */
    isCompanyMetaDataValid(): boolean {
        const companyMetaData = this.companyMetadataForm.get('companyMetaData');
        const accountingSoftwareTypeValid = companyMetaData.get('accountingSoftwareTypeId').valid;
        const monthlyTransactionAmountValid = companyMetaData.get('monthlyTransactionAmount').valid;
        const companyTypeId = this.companyTypeId;
        const coopAssociationTypeIdValid = companyMetaData.get('coopAssociationTypeId').valid;
        const otherCoopAssociationTypeValid = companyMetaData.get('otherCoopAssociationType').valid;
        const accountingSoftwareValid = companyMetaData.get('accountingSoftware').valid;
        if (!accountingSoftwareTypeValid || !monthlyTransactionAmountValid) {
            return false;
        }
        if (companyMetaData.get('accountingSoftwareTypeId').value === '11' && !accountingSoftwareValid) {
            return false;
        }

        if (companyTypeId === '4') {
            if (!coopAssociationTypeIdValid) {
                return false;
            }

            if (companyMetaData.get('coopAssociationTypeId').value === '26' && !otherCoopAssociationTypeValid) {
                return false;
            }
        }
        return true;
    }

    /**
     * @summary checks if the other apartment is selected
     * @returns boolean
     */
    checkIfOtherApartment(): boolean {
        return this.apartmentSelected.includes('Other');
    }

    validateMonthlyTransactionAmount(control: AbstractControl): {[key: string]: any} | null {
        const value = control.value;
        if (value === 0) {
            control.setValue(null);
        }
        return value && (value <= 0 || value > 10000000000) ? {invalidAmount: true} : null;
    }

    onSubmit() {
        if (this.companyMetadataForm.disabled || (this.isCompanyMetaDataValid() && this.isRealEstateDataValid())) {
            const companyMetaData = this.companyMetadataForm.get('companyMetaData').value;
            companyMetaData.monthlyTransactionAmount = companyMetaData.monthlyTransactionAmount.toString();
            companyMetaData.accountingSoftwareTypeId =
                companyMetaData.accountingSoftwareTypeId == '0' ? null : companyMetaData.accountingSoftwareTypeId;
            const companyRealEstate = this.companyMetadataForm.get('companyRealEstate').value;
            this.kycDataService.updateStepValidity('companyMetaData', true);
            if (!this.isKycCompleted) {
                this.apiService.submitCompanyMetadata(companyMetaData, companyRealEstate).subscribe(() => {});
            }
        } else {
            if (this.companyMetadataForm?.get('companyRealEstate')?.get('realEstates')?.status === 'INVALID') {
                this.global.basicAlert(
                    '',
                    'Please fill all the required fields and select at least one property type.',
                    true
                );
            }
            this.companyMetadataForm.get('companyMetaData').markAllAsTouched();
            this.companyMetadataForm.get('companyRealEstate').markAllAsTouched();
        }
    }
}
