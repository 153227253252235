import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {GlobalService} from 'src/app/services/global.service';
import {ModalController, NavParams} from '@ionic/angular';
import {ProfileService} from '../../profile/profile.service';
import * as moment from 'moment';
import {ApiResponseService} from 'src/app/services/api-response.service';

@Component({
    selector: 'app-send-feedback',
    templateUrl: './send-feedback.modal.html',
})
// tslint:disable-next-line:component-class-suffix
export class SendFeedbackModal {
    feedbackData = {};
    feedbacks = [];
    page = 1;
    limit = 5;
    totalCount = 0;
    constructor(public global: GlobalService, private readonly profileService: ProfileService, public apiResponseService: ApiResponseService) {}
    ngOnInit() {
        this.patchProfileData();
        this.getFeedbacks();
    }
    submitFeedback(sendFeedbackForm) {
        if (this.global.isFormValid(sendFeedbackForm)) {
            this.global.showLoading();
            const emailControl = sendFeedbackForm.controls['email'];
            if (emailControl) {
                emailControl.markAsTouched();
                emailControl.updateValueAndValidity();
            }
            this.global
                .sendAjaxRequest('admin/submitFeedback', {
                    request: this.feedbackData,
                })
                .subscribe((response) => {
                    if (response.message === 'success') {
                        this.global.basicAlert('', 'label.ticket_submitted', true);
                        this.global.hideLoading();
                        this.getFeedbacks();
                        this.feedbackData = {};
                        this.global.closeModal();
                    }
                });
        }
    }

    getFeedbacks() {
        this.global.showLoading();
        this.apiResponseService.getFeedbacks(this.page, this.limit).subscribe((response) => {
            this.feedbacks = response.data;
            this.totalCount = response.meta.totalCount;
            this.global.hideLoading();
        });
    }
    toLocal(date: Date) {
        return moment(date).format('MMM Do, YYYY');
    }
    onPaginationChanged($event) {
        this.page = $event;
        this.getFeedbacks();
    }
    private patchProfileData() {
        this.profileService.profileDetails$.subscribe((profileData) => {
            const notificationEmail = profileData?.notificationEmail || '';
            const emailToUse = notificationEmail || profileData?.email || '';
            this.feedbackData['email'] = emailToUse;
        });
    }
}
