<!-- <div style="width: 850px; padding: 60px;"> -->
<ion-header>
    <ion-toolbar>
        <ion-title class="ion-text-capitalize toolbar-title">
            <span>Add</span>
        </ion-title>
        <ion-button slot="end" fill="none" (click)="global.closeModal(false)">
            <ion-icon name="close" class="border-black rounded-full"></ion-icon>
        </ion-button>
    </ion-toolbar>
</ion-header>
<div class="overflow-auto p-[2.75rem] pt-0">
    <form #saveStakeHolder="ngForm" novalidate>
        <ion-grid>
            <div class="m-0 responsive-section rounded no-top-borders no-bottom-borders no-borders">
                <ion-row>
                    <ion-col size="6" size-sm="12" size-lg="6" size-xs="12" size-md="6">
                        <app-form-field label="label.first_name">
                            <mat-form-field>
                                <input
                                    matInput
                                    [(ngModel)]="stakeholders['firstName']"
                                    name="firstName"
                                    class="pr-2"
                                    #firstName="ngModel"
                                    ngControl="stakeholders['firstName']"
                                    appNoLeadingTrailingSpaces
                                    required />
                                <mat-error appFieldErrors></mat-error>
                            </mat-form-field>
                        </app-form-field>
                    </ion-col>
                    <ion-col size="6" size-sm="12" size-lg="6" size-xs="12" size-md="6">
                        <app-form-field label="label.last_name" class="">
                            <mat-form-field>
                                <input
                                    matInput
                                    [(ngModel)]="stakeholders['lastName']"
                                    name="lastName"
                                    #lastName="ngModel"
                                    appNoLeadingTrailingSpaces
                                    required />
                                <mat-error appFieldErrors></mat-error>
                            </mat-form-field>
                        </app-form-field>
                    </ion-col>
                </ion-row>
                <app-form-field
                    *ngIf="companyType!=='4'"
                    label="Does this official hold one or more of the following designations ?"
                    class="mb-3">
                </app-form-field>
                <ion-row class="placeholder-amber-100" *ngIf="companyType!=='4'">
                    <ng-container *ngIf="stakeholderDesignationOptions?.length > 0; else loading">
                        <ng-container *ngFor="let stakeholderDesignation of stakeholderDesignationOptions">
                            <ng-container [ngSwitch]="stakeholderDesignation.kycStakeholderDesignationTypeId">
                                <ng-container *ngSwitchCase="4">
                                    <ion-col size="5" size-xs="12" size-md="5" size-lg="5" size-sm="12">
                                        <mat-checkbox
                                            [color]="'primary'"
                                            class="example-margin"
                                            (change)="checkboxOnclick($event)"
                                            [checked]="isDesignationSelected(stakeholderDesignation.kycStakeholderDesignationTypeId)"
                                            name="director"
                                            [value]="stakeholderDesignation.kycStakeholderDesignationTypeId"
                                            >{{stakeholderDesignation.value}}
                                            <app-form-field
                                                [showButtonInfo]="true"
                                                label=""
                                                [matTooltipText]="UBOinfo()"></app-form-field>
                                        </mat-checkbox>
                                    </ion-col>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <ion-col size="5">
                                        <mat-checkbox
                                            [color]="'primary'"
                                            class="example-margin flex"
                                            [checked]="isDesignationSelected(stakeholderDesignation.kycStakeholderDesignationTypeId)"
                                            (change)="checkboxOnclick($event)"
                                            name="director"
                                            [value]="stakeholderDesignation.kycStakeholderDesignationTypeId"
                                            >{{stakeholderDesignation.value}}</mat-checkbox
                                        >
                                    </ion-col>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>

                    <ng-template #loading>
                        <ion-col size="12" class="ion-text-center">
                            <ion-spinner name="crescent"></ion-spinner>
                        </ion-col>
                    </ng-template>
                </ion-row>
                <ion-row>
                    <ion-col size="6" class="mt-4" class="" size-sm="12" size-lg="6" size-xs="12" size-md="6">
                        <app-form-field label="label.dob" required>
                            <mat-form-field>
                                <input
                                    matInput
                                    required
                                    [max]="today"
                                    [matDatepicker]="dob"
                                    placeholder="dd/mm/yyyy"
                                    name="dob"
                                    [(ngModel)]="stakeholders['dob']" />
                                <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
                                <mat-datepicker #dob></mat-datepicker>
                                <mat-error appFieldErrors></mat-error>
                            </mat-form-field>
                        </app-form-field>
                    </ion-col>
                    <ion-col size="6" class="mt-4" class="" size-sm="12" size-lg="6" size-xs="12" size-md="6">
                        <app-form-field label="label.gender" required>
                            <mat-form-field>
                                <mat-select
                                    [(ngModel)]="selectedGender"
                                    name="gender"
                                    (selectionChange)="setGender($event)"
                                    required
                                    panelClass="mat-select-option-panal"
                                    disableOptionCentering>
                                    <mat-option *ngFor="let options of genders" value="{{options.name}}"
                                        >{{options.name}}</mat-option
                                    >
                                </mat-select>
                                <mat-error appFieldErrors></mat-error>
                            </mat-form-field>
                        </app-form-field>
                    </ion-col>
                    <ion-col size="6" size-sm="12" size-lg="6" size-xs="12" size-md="6">
                        <app-form-field label="label.nationality" required class="">
                            <mat-form-field>
                                <mat-select
                                    [(ngModel)]="selectedNationality"
                                    name="nationality"
                                    (selectionChange)="setNationality($event)"
                                    required
                                    panelClass="mat-select-option-panal"
                                    disableOptionCentering>
                                    <mat-option
                                        *ngFor="let option of nationalitiesOptions"
                                        value="{{option.alpha2Code}}">
                                        {{option.nationality}} ({{option.alpha2Code}})
                                    </mat-option>
                                </mat-select>
                                <mat-error appFieldErrors></mat-error>
                            </mat-form-field>
                        </app-form-field>
                    </ion-col>
                    <ion-col size="6" size-sm="12" size-lg="6" size-xs="12" size-md="6">
                        <app-form-field label="label.phone_number">
                            <mat-form-field>
                                <input
                                    matInput
                                    [(ngModel)]="stakeholders['phoneNumber']"
                                    name="phoneNumber"
                                    #phoneNumber="ngModel"
                                    mask="(000) 000-0000" />
                                <mat-error appFieldErrors></mat-error>
                            </mat-form-field>
                        </app-form-field>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="6" class="" size-sm="12" size-lg="6" size-xs="12" size-md="6">
                        <app-form-field label="label.industry" required>
                            <mat-form-field>
                                <mat-select
                                    (selectionChange)="setEmploymentSector($event)"
                                    name="industry"
                                    [(ngModel)]="stakeholderEmploymenSector"
                                    required
                                    panelClass="mat-select-option-panal"
                                    disableOptionCentering>
                                    <mat-option
                                        *ngFor="let sector of employmentSectors"
                                        [value]="sector.kycEmploymentSectorId">
                                        {{ sector.value }}
                                    </mat-option>
                                </mat-select>
                                <mat-error appFieldErrors></mat-error>
                            </mat-form-field>
                        </app-form-field>
                    </ion-col>
                    <ion-col size="6" class="" size-sm="12" size-lg="6" size-xs="12" size-md="6">
                        <app-form-field label="label.occupation" [required]="occupationRequired">
                            <mat-form-field>
                                <input
                                    matInput
                                    [(ngModel)]="stakeholders['occupation']"
                                    name="occupation"
                                    #occupation="ngModel"
                                    [required]="occupationRequired"
                                    appNoLeadingTrailingSpaces />
                                <mat-error appFieldErrors></mat-error>
                            </mat-form-field>
                        </app-form-field>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="12" class="">
                        <app-form-field label="Address" class=" ">
                            <location-search-v2
                                [required]="googleAddress"
                                label="set_address_using_google_canada"
                                [fixedCountry]="'CA'"
                                [completeAddress]="stakeholders['fullAddress']"
                                (onPlaceChanged)="locationResults($event)"></location-search-v2>
                        </app-form-field>
                    </ion-col>
                </ion-row>
                <ion-row [hidden]="hideOtherFields">
                    <ion-col size="6" class="" size-sm="12" size-lg="6" size-xs="12" size-md="6">
                        <app-form-field label="label.build_street_number" required>
                            <mat-form-field>
                                <input
                                    matInput
                                    name="buildingNumber"
                                    [(ngModel)]="stakeholders['buildingNumber']"
                                    #buildingNumber="ngModel"
                                    ngControl="stakeholders['buildingNumber']"
                                    required />
                                <mat-error appFieldErrors></mat-error>
                            </mat-form-field>
                        </app-form-field>
                    </ion-col>
                    <ion-col size="6" class="" size-sm="12" size-lg="6" size-xs="12" size-md="6">
                        <app-form-field label="label.street_name" required>
                            <mat-form-field>
                                <input
                                    matInput
                                    name="streetName"
                                    [(ngModel)]="stakeholders['streetName']"
                                    #streetName="ngModel"
                                    ngControl="stakeholders['streetName']"
                                    required />
                                <mat-error appFieldErrors></mat-error>
                            </mat-form-field>
                        </app-form-field>
                    </ion-col>
                    <ion-col size="6" class="" size-sm="12" size-lg="6" size-xs="12" size-md="6">
                        <app-form-field label="label.city">
                            <mat-form-field>
                                <input
                                    matInput
                                    name="city"
                                    [(ngModel)]="stakeholders['city']"
                                    #city="ngModel"
                                    ngControl="stakeholders['city']"
                                    required />
                                <mat-error appFieldErrors></mat-error>
                            </mat-form-field>
                        </app-form-field>
                    </ion-col>
                    <ion-col size="6" class="" size-sm="12" size-lg="6" size-xs="12" size-md="6">
                        <app-form-field label="label.province">
                            <mat-form-field>
                                <input
                                    matInput
                                    name="province"
                                    [(ngModel)]="stakeholders['province']"
                                    #province="ngModel"
                                    ngControl="building['province']"
                                    required />
                                <mat-error appFieldErrors></mat-error>
                            </mat-form-field>
                        </app-form-field>
                    </ion-col>
                    <ion-col size="6" class="" size-sm="12" size-lg="6" size-xs="12" size-md="6">
                        <app-form-field label="label.country">
                            <mat-form-field>
                                <input
                                    matInput
                                    name="country"
                                    [(ngModel)]="stakeholders['country']"
                                    #country="ngModel"
                                    ngControl="building['country']"
                                    [disabled]="true"
                                    required />
                                <mat-error appFieldErrors></mat-error>
                            </mat-form-field>
                        </app-form-field>
                    </ion-col>
                    <ion-col size="6" class="" size-sm="12" size-lg="6" size-xs="12" size-md="6">
                        <app-form-field label="label.postal_code" required>
                            <mat-form-field>
                                <input
                                    required
                                    matInput
                                    name="postalCode"
                                    [(ngModel)]="stakeholders['postalCode']"
                                    #postalCode="ngModel"
                                    ngControl="building['postalCode']" />
                                <mat-error appFieldErrors></mat-error>
                            </mat-form-field>
                        </app-form-field>
                    </ion-col>
                </ion-row>
            </div>
        </ion-grid>
    </form>
</div>
<ion-footer>
    <ion-button class="btn__primary btn__primary-outlined" (click)="global.closeModal(false)"
        >{{'label.cancel' | translate}}</ion-button
    >

    <ion-button class="btn__primary" (click)="saveStakeHolders(saveStakeHolder)"
        >{{'label.save' | translate}}</ion-button
    >
</ion-footer>

<!-- </div> -->
