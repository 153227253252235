import {Injectable} from '@angular/core';
import {EMPTY, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {GlobalService} from '../../services/global.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    /** exception URLs on which the alert won't trigger */
    errorExceptionRegex = new RegExp(/yardi\/getYardiConfigs/);

    errorExceptionRegexForLogs = new RegExp(/yardi\/getXmlLog.*/);

    /** constructor */
    constructor(private readonly global: GlobalService) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * token interceptor which adds the authentication token on every ongoing node-api request
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // this condition will make sure not to append token on php apis
        if (!req.url.includes('api')) {
            return next.handle(req);
        }

        const modifiedReq = this.setToken(req);
        return next.handle(modifiedReq).pipe(
            map((response) => response),
            catchError(this.handleError)
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * append the auth token on http request header only if the token exists in the local storage
     *
     * @returns modified request with auth token req header
     */
    private setToken(req: HttpRequest<any>): HttpRequest<any> {
        const tempToken = this.global.getLocalStorage('tenantpayToken');
        // if the content type is set then it must be a php server api and should not be touched
        if (req.headers.get('Content-Type')) {
            return req;
        }

        // if token does not exist in the local storage then return the original request
        if (tempToken === 'false' || tempToken === '') {
            return req;
        }

        return req.clone({
            headers: new HttpHeaders({
                Authorization: `Bearer ${tempToken}`,
            }),
        });
    }

    /**
     * handles the error handling in api failing
     */
    private handleError = (errorResponse: HttpErrorResponse) => {
        const {error, url} = errorResponse;

        if (this.errorExceptionRegex.test(url)) {
            return of(error);
        }

        /**In case of getting yardi transactions xml logs if there is error of not found we check if the user is trying to obtain the logs older than 30 days and show messgage accordingly */
        if (this.errorExceptionRegexForLogs.test(url)) {
            if (errorResponse.status === 404) {
                const transactionDateString = this.global.yardiTransactionDateForLog;
                const transactionDate = new Date(transactionDateString);
                const currentDate = new Date();
                const daysDifference = Math.floor((currentDate.getTime() - transactionDate.getTime()) / (1000 * 60 * 60 * 24));
                if (daysDifference > 30) {
                    this.global.basicAlert('', 'label.log_removed', true);
                }
                return of(error);
            }
        }
        if (error.message === 'User does not exist') {
            this.global.basicAlert('', 'label.user_does_not_exist', true);
        } else if (error.message === 'Unauthorized') {
            this.global.closeModal();
            this.global.logoutUser();
            this.global.basicAlert('', 'errors.unauthorized', true);
        } else if (errorResponse.status === 400) {
            this.displayBadRequestMessage(error.message);
        } else if (errorResponse.status === 404 && error.message == 'company_info_not_found') {
            // this is for case when kyc id isn't created and user is new
        } else if (errorResponse.status === 404 && url.includes('company-setting')) {
        } else if (errorResponse.status === 404 && url.includes('accountStatus')) {
        } else if (errorResponse.status === 404) {
            this.global.basicAlert('', errorResponse.statusText, false);
        } else if (errorResponse.status === 401 || errorResponse.status === 409) {
            this.global.basicAlert('', error.message, true);
        } else if (errorResponse.status === 403 && error.message === 'Forbidden resource') {
            this.global.yardiLoading = false;
            this.global.hideLoading();
            this.global.basicAlert('', 'Not authorized', true);
        } else {
            this.global.basicAlert('', 'errors.something_went_wrong', true);
        }
        return of(error);
    };

    displayBadRequestMessage(message) {
        this.global.basicAlert('', `label.${message}`, true);
    }
}
