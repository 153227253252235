<!-- <div class="light-background overflow-auto" cdkScrollable> -->
<!-- page heading -->

<!-- table data with filters -->
<!-- <app-table-with-filters> -->
<!-- filters -->

<!-- </app-table-with-filters> -->
<ion-content class="horz-center flex justify-center" style="width: 100%">
    <app-page-header
        [pageHeading]="global.loggedInUser?.companyId && global.loggedInUser?.pendingKycId ? 'label.kyc' : 'label.get_started'"></app-page-header>
    <ion-card class="form-container horz-center justify-self-center">
        <mat-stepper
            #stepper
            class="stepper pb-7"
            [selectedIndex]="step-2"
            class="w-[75%] pb-[60px]"
            *ngIf="!(companyTypeId === '1' && step === 5) && !((step === 6|| step===7)  && companyTypeId!=='')"
            [ngClass]="{'hide-breadcrumb': this.hideBreadCrumb()}">
            <ng-template matStepperIcon="edit">
                <mat-icon class="pl-0.5"> &#10004;</mat-icon>
            </ng-template>

            <ng-template matStepperIcon="done">
                <mat-icon aria-hidden="false" `-label="Example home icon" fontIcon="home"></mat-icon>
            </ng-template>
            <ng-template matStepperIcon="number">
                <mat-icon class="pl-0.5"> &#10004;</mat-icon>
            </ng-template>

            <mat-step [completed]="step>2">
                <ng-template matStepLabel>
                    <span [ngClass]="{'completed-label': step >=2}">{{'label.business_info'|translate}}</span>
                </ng-template>
            </mat-step>
            <mat-step [completed]="step>3">
                <ng-template matStepLabel>
                    <span [ngClass]="{'completed-label': step >=3}">{{'label.usage_info'|translate}}</span>
                </ng-template>
            </mat-step>
            <mat-step *ngIf="companyTypeId!=='1' " [completed]="step>4">
                <ng-template matStepLabel>
                    <span [ngClass]="{'completed-label': step >=4}">
                        {{ companyTypeId === '4' ? 'Directors' : 'Stakeholders' }}</span
                    >
                </ng-template>
            </mat-step>
            <mat-step *ngIf="kycDataService.incompleteBuildingDetails " [completed]="step>5">
                <ng-template matStepLabel>
                    <span [ngClass]="{'completed-label': step >=5}">Building Details</span>
                </ng-template>
            </mat-step>
        </mat-stepper>
        <div>
            <div class="pl-[3.75rem] pr-[3.75rem]">
                <router-outlet (activate)="onActivate($event)"></router-outlet>
            </div>
            <ion-row class="flex justify-end mr-10 pr-[3.75rem]">
                <ion-button
                    class="btn__primary-outlined mr-5"
                    *ngIf="shouldShowElement()"
                    (click)="previousStep()"
                    style="float: right"
                    >{{'label.back' | translate}}
                </ion-button>
                <ion-button
                    class="btn__primary mr-5"
                    [disabled]="kycDataService.disableNextBtn"
                    *ngIf="showNextStep()"
                    (click)="nextStep()"
                    >{{'label.next' | translate}}</ion-button
                >
            </ion-row>
        </div>
    </ion-card>
    <!-- </ion-item> -->
</ion-content>

<!-- </div> -->
