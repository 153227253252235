<!-- <div style="width: 850px; padding: 60px;"> -->
<ion-header>
    <ion-toolbar>
        <ion-title class="ion-text-capitalize toolbar-title">
            <span>Update Building </span>
        </ion-title>
        <ion-button slot="end" fill="none" (click)="global.closeModal(false)">
            <ion-icon name="close" class="border-black rounded-full"></ion-icon>
        </ion-button>
    </ion-toolbar>
</ion-header>
<div class="overflow-auto p-[2.75rem] pt-0">
    <form #saveBuildingForm="ngForm" novalidate>
        <ion-grid>
            <div class="m-0 responsive-section rounded no-top-borders no-bottom-borders no-borders">
                <ion-row>
                    <ion-col size="6" size-sm="12" size-lg="6" size-xs="12" size-md="6">
                        <app-form-field label="label.building_name">
                            <mat-form-field>
                                <input
                                    matInput
                                    [(ngModel)]="building['name']"
                                    name="name"
                                    class="pr-2"
                                    #buildingName="ngModel"
                                    ngControl="building['firstName']"
                                    required />
                                <mat-error appFieldErrors></mat-error>
                            </mat-form-field>
                        </app-form-field>
                    </ion-col>
                    <ion-col size="6" size-sm="12" size-lg="6" size-xs="12" size-md="6">
                        <app-form-field label="label.bank_account" class="">
                            <mat-form-field>
                                <input
                                    disabled="true"
                                    matInput
                                    [(ngModel)]="bankAccountName"
                                    name="bankAccount"
                                    #bankAccount="ngModel"
                                    required />
                                <mat-error appFieldErrors></mat-error>
                            </mat-form-field>
                        </app-form-field>
                    </ion-col>
                    <ion-col size="6" size-sm="12" size-lg="6" size-xs="12" size-md="6">
                        <app-form-field label="label.number_of_units" class="">
                            <mat-form-field>
                                <input
                                    required
                                    matInput
                                    [(ngModel)]="building['totalUnits']"
                                    name="number_of_units"
                                    label="Number of Units"
                                    #totalUnits="ngModel"
                                    ngControl="building['totalUnits']"
                                    mask="9999999999" />
                                <mat-error appFieldErrors></mat-error>
                            </mat-form-field>
                        </app-form-field>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col size="12" class="">
                        <app-form-field label="Address" class=" ">
                            <location-search-v2
                                [required]="googleAddress"
                                [completeAddress]="fullAddress"
                                label="set_address_using_google_canada"
                                [fixedCountry]="'CA'"
                                (onPlaceChanged)="locationResults($event)"></location-search-v2>
                        </app-form-field>
                    </ion-col>
                </ion-row>
                <ion-row [hidden]="hideOtherFields">
                    <ion-col size="6" class="" size-sm="12" size-lg="6" size-xs="12" size-md="6">
                        <app-form-field label="label.build_street_number" required>
                            <mat-form-field>
                                <input
                                    matInput
                                    name="buildingNumber"
                                    [(ngModel)]="building['buildingNumber']"
                                    #buildingNumber="ngModel"
                                    ngControl="building['buildingNumber']"
                                    required />
                                <mat-error appFieldErrors></mat-error>
                            </mat-form-field>
                        </app-form-field>
                    </ion-col>
                    <ion-col size="6" class="" size-sm="12" size-lg="6" size-xs="12" size-md="6">
                        <app-form-field label="label.street_name" required>
                            <mat-form-field>
                                <input
                                    matInput
                                    name="streetName"
                                    [(ngModel)]="building['streetName']"
                                    #streetName="ngModel"
                                    ngControl="building['streetName']"
                                    required />
                                <mat-error appFieldErrors></mat-error>
                            </mat-form-field>
                        </app-form-field>
                    </ion-col>
                    <ion-col size="6" class="" size-sm="12" size-lg="6" size-xs="12" size-md="6">
                        <app-form-field label="label.city">
                            <mat-form-field>
                                <input
                                    matInput
                                    name="city"
                                    [(ngModel)]="building['city']"
                                    #city="ngModel"
                                    ngControl="building['city']"
                                    required />
                                <mat-error appFieldErrors></mat-error>
                            </mat-form-field>
                        </app-form-field>
                    </ion-col>
                    <ion-col size="6" class="" size-sm="12" size-lg="6" size-xs="12" size-md="6">
                        <app-form-field label="label.province">
                            <mat-form-field>
                                <input
                                    matInput
                                    name="province"
                                    [(ngModel)]="building['province']"
                                    #province="ngModel"
                                    ngControl="building['province']"
                                    required />
                                <mat-error appFieldErrors></mat-error>
                            </mat-form-field>
                        </app-form-field>
                    </ion-col>
                    <ion-col size="6" class="" size-sm="12" size-lg="6" size-xs="12" size-md="6">
                        <app-form-field label="label.country">
                            <mat-form-field>
                                <input
                                    matInput
                                    name="country"
                                    [(ngModel)]="building['country']"
                                    #country="ngModel"
                                    ngControl="building['country']"
                                    required />
                                <mat-error appFieldErrors></mat-error>
                            </mat-form-field>
                        </app-form-field>
                    </ion-col>
                    <ion-col size="6" class="" size-sm="12" size-lg="6" size-xs="12" size-md="6">
                        <app-form-field label="label.postal_code" required>
                            <mat-form-field>
                                <input
                                    required
                                    matInput
                                    name="postalCode"
                                    [(ngModel)]="building['postalCode']"
                                    #postalCode="ngModel"
                                    ngControl="building['postalCode']" />
                                <mat-error appFieldErrors></mat-error>
                            </mat-form-field>
                        </app-form-field>
                    </ion-col>
                </ion-row>
            </div>
        </ion-grid>
    </form>
</div>
<ion-footer>
    <ion-button class="btn__primary btn__primary-outlined" (click)="global.closeModal(false)"
        >{{'label.cancel' | translate}}</ion-button
    >

    <ion-button class="btn__primary" (click)="saveBuilding(saveBuildingForm)" *ngIf="isEdited()"
        >{{'label.save' | translate}}</ion-button
    >
</ion-footer>

<!-- </div> -->
