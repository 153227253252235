import {Component, EventEmitter, Input, Output, OnChanges} from '@angular/core';
import {GlobalService} from '../../services/global.service';

@Component({
    selector: 'file-uploader',
    template: '',
    // templateUrl: 'file-uploader.component.html',
    // styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent implements OnChanges {
    @Input() inputData;
    @Input() uploadedFiles = [];
    @Input() options;
    @Input() triggerExternalClick = 0;
    @Input() disabled = false;
    @Input() maxFileSize;

    @Input() multipleFileUpload = false;
    @Input() allowedFileTypes = '';

    @Output() afterUpload = new EventEmitter();
    @Output() triggerExternalClickChange = new EventEmitter();

    public fileUpload = true;
    public uploaderName = '';
    public _afterUploadFiles;
    constructor(public global: GlobalService) {
        this.uploaderName = this.global.generateRandomNumber(1, 30);
        // eslint-disable-next-line no-underscore-dangle
        this._afterUploadFiles = this.global.eventBus
            .getEvent('afterUploadFiles' + this.uploaderName)
            .subscribe((data) => {
                this.uploadedFiles = [];
                if (Array.isArray(data)) {
                    data.forEach((file) => {
                        this.uploadedFiles.push(file);
                    });
                } else {
                    this.uploadedFiles.push(data);
                }
                this.afterUpload.emit(this.uploadedFiles);
                this.global.hideLoading();
            });
    }

    ngOnInit() {
        setTimeout(() => {
            this.global.multipleFileUpload = this.multipleFileUpload;
        }, 1);

        // initally it should be always 0 in case its change after initalize
        this.triggerExternalClick = 0;
    }

    ngOnDestroy() {}

    onClick() {
        if (this.disabled) {
            return;
        }
        this.global.FILEOF = {};
        this.global.FILEOF = this.global._.cloneDeep(this.inputData);
        this.global.FILEOF['event'] = 'afterUploadFiles' + this.uploaderName;
        this.global.clickFileUpload(this.allowedFileTypes, this.maxFileSize);
    }

    ngOnChanges(inputs) {
        if (inputs['triggerExternalClick'] && inputs['triggerExternalClick'].currentValue > 0) {
            this.onClick();
        }
    }
}
